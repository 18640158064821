import React from "react";
import { Helmet } from "react-helmet";

import { PageLayout } from "../layouts";
import { FeaturesBlock, Block, GetStartedStackedBlock } from '../components';

import "../global/styles/page-styles/features-page.scss";

/**
 * Assets for Hero.
 */
import Waves from "../images/global/waves.svg";
import PetsSrc from "../images/features-hero-pets.svg";
import Player1Src from "../images/features-hero-player01.svg";
import Player2Src from "../images/features-hero-player02.svg";
import Popcorn from "../images/features-hero-popcorn.svg";
import SpaceInvader from "../images/space-invader.svg";
import Deco from "../images/features-hero-deco.svg";

const FeaturesPage = () => {
  return (
    <>
      <Helmet>
        <title>Partie | Features</title>
        <html lang="en" />
        <meta name="description" content="Connect and play with others through all-in-one Partie Matchmaking, Voice, Video and Text Chat" />
      </Helmet>
      <PageLayout className="features-page">
        <Block centered hero lazy>
          <h2 className="partie-block__title">Join the Partie</h2>
          <p className="partie-block__description">
            Connect and play with others through all-in-one Partie Matchmaking,
            Voice, Video and Text Chat.
          </p>
          <div className="features__background" aria-hidden>
            <img className="waves-1" src={Waves} alt="" aria-hidden />
            <img className="waves-2" src={Waves} alt="" aria-hidden />
            <img className="popcorn" src={Popcorn} alt="" aria-hidden />
            <img className="space-invader" src={SpaceInvader} alt="" aria-hidden />
            <img className="deco" src={Deco} alt="" aria-hidden />
            <img className="pets-img" src={PetsSrc} alt="" aria-hidden />
            <img className="player-1" src={Player1Src} alt="" aria-hidden />
            <img className="player-2" src={Player2Src} alt="" aria-hidden />
          </div>
        </Block>
        <FeaturesBlock />
        <GetStartedStackedBlock />
      </PageLayout>
    </>
  );
};

export default FeaturesPage;
